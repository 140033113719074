import { cancelRequest, updateRequestStatus } from '../apis/request-api';
import { requestActions } from './enums/request-actions';
import * as sweetAlertService from './sweet-alert-service';

async function handleRequestReject() {
  const userInput = await sweetAlertService.userInputPopup(
    'Reject Request',
    'Please state your reason for rejecting this request',
    sweetAlertService.sweetAlertInputType.TEXTAREA,
  );
  return userInput;
}

export async function handleRequestActionButtonClick(
  action,
  status,
  userEmail,
  startDate,
  requestId,
  endDate = null,
  requestorName = null,
  fullAMPM = null,
) {
  const userConfirmed = await sweetAlertService.userConfirmationPopup(
    `${action} Request`,
    `Are you sure you wish to ${action} this request?`,
  );

  try {
    if (userConfirmed) {
      let rejectionReason;
      switch (status) {
        case requestActions.CANCEL:
          await cancelRequest(userEmail, startDate, requestId);
          break;
        case requestActions.REJECT:
          rejectionReason = await handleRequestReject();
          if (rejectionReason === false) {
            break;
          }
          await updateRequestStatus(
            userEmail,
            startDate,
            requestId,
            status,
            rejectionReason,
            endDate,
            requestorName,
            fullAMPM,
          );
          break;
        case requestActions.APPROVE:
          await updateRequestStatus(userEmail, startDate, requestId, status, '', endDate, requestorName, fullAMPM);
          break;
        default:
          sweetAlertService.alertPopup(
            `${action} Request Error`,
            `Invalid request action type, must be either a cancel, approve or reject request.`,
            sweetAlertService.sweetAlertPopupType.ERROR,
          );
          break;
      }

      sweetAlertService.alertPopup(`${action} Request`, 'Successful', sweetAlertService.sweetAlertPopupType.SUCCESS);
    }
  } catch (error) {
    sweetAlertService.alertPopup(
      `${action} Request Error`,
      `An error has occured when attempting to ${action} the request. Please try again, or contact the BSD team.`,
      sweetAlertService.sweetAlertPopupType.ERROR,
    );
  }
}
