import EditIcon from '@mui/icons-material/Edit';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import WorkIcon from '@mui/icons-material/Work';
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { getAllDepartments } from '../../apis/department-api';
import { getAllTeams } from '../../apis/teams-api';
import { getAllUsers } from '../../apis/user-api';
import ContentTable from '../../components/content/ContentTable';
import CreateDepartmentFormModal from './components/CreateDepartmentFormModal';
import CreateTeamFormModal from './components/CreateTeamFormModal';
import EditUserFormModal from './components/EditUserFormModal';
import CreateUserFormModal from './components/CreateUserFormModal';

export default function Admin() {
  const [isLoading, setIsLoading] = useState(false);
  const [adminMenuOpen, setAdminMenuOpen] = React.useState(false);
  const [menu, setMenu] = useState('users');
  const handleAdminMenuOpen = () => setAdminMenuOpen(true);
  const handleAdminMenuClose = () => setAdminMenuOpen(false);

  // Create Department Modal
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [createDepartmentOpen, setCreateDepartmentOpen] = useState(false);
  const handleCreateDepartmentOpen = () => setCreateDepartmentOpen(true);
  const handleCreateDepartmentClose = () => {
    setCreateDepartmentOpen(false);
    setSelectedDepartment(null);
    loadDepartmentTable();
  };

  // Create Team Modal
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [createTeamOpen, setCreateTeamOpen] = useState(false);
  const handleCreateTeamOpen = () => setCreateTeamOpen(true);
  const handleCreateTeamClose = () => {
    setSelectedTeam(null);
    setCreateTeamOpen(false);
    loadTeamsTable();
  };

  // Edit User Modal
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const handleEditUserOpen = () => setEditUserOpen(true);
  const handleEditUserClose = () => {
    setEditUserOpen(false);
    setSelectedUser(null);
    loadUserTable();
  };

  // Create User Modal
  const [createUserOpen, setCreateUserOpen] = useState(false);
  const handleCreateUserOpen = () => setCreateUserOpen(true);
  const handleCreateUserClose = () => {
    setCreateUserOpen(false);
    loadUserTable();
  };

  // Content Table
  const [tableHeaders, setTableHeaders] = useState(['']);
  const [tableData, setTableData] = useState([{}]);
  const tableHeadersRef = useRef(tableHeaders);
  const tableDataRef = useRef(tableData);

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      await loadUserTable();
      setIsLoading(false);
    }
    onLoad();
  }, []);

  async function getUsers() {
    let users = [{}];
    users = await getAllUsers(true);
    return users.map((user) => {
      return {
        fullName: user.isDisabled ? `${user.name} (DISABLED)` : user.name,
        departmentName: user.departmentName,
        teamName: user.teamName,
        button: (
          <IconButton
            onClick={async () => {
              handleUserViewClick(user);
            }}
          >
            <EditIcon />
          </IconButton>
        ),
      };
    });
  }

  async function getDepartments() {
    const departments = await getAllDepartments();
    return departments.map((department) => {
      return {
        departmentName: department.departmentName,
        button: (
          <IconButton
            onClick={async () => {
              handleDepartmentViewClick(department);
            }}
          >
            <EditIcon />
          </IconButton>
        ),
      };
    });
  }

  async function getTeams() {
    const teams = await getAllTeams();

    return teams.map((team) => {
      return {
        teamName: team.teamName,
        departmentName: team.departmentName,
        manager: team.managerEmail.split('@')[0].replaceAll('.', ' '),
        button: (
          <IconButton
            onClick={async () => {
              handleTeamViewClick(team);
            }}
          >
            <EditIcon />
          </IconButton>
        ),
      };
    });
  }

  async function loadUserTable() {
    setIsLoading(true);
    setMenu('users');
    const users = await getUsers();
    setTableData(users);
    tableDataRef.current = users;
    setTableHeaders(['Name', 'Department', 'Team', '']);
    tableHeadersRef.current = ['Name', 'Department', 'Team', ''];
    setIsLoading(false);
  }

  async function loadDepartmentTable() {
    setIsLoading(true);
    setMenu('departments');
    const departments = await getDepartments();
    setTableData(departments);
    tableDataRef.current = departments;
    setTableHeaders(['Department Name', '']);
    tableHeadersRef.current = ['Department Name', ''];
    setIsLoading(false);
  }

  async function loadTeamsTable() {
    setIsLoading(true);
    setMenu('teams');
    const teams = await getTeams();
    setTableData(teams);
    tableDataRef.current = teams;
    setTableHeaders(['Team', 'Department', 'Manager', '']);
    tableHeadersRef.current = ['Team', 'Department', ''];
    setIsLoading(false);
  }

  async function handleUserViewClick(user) {
    setSelectedUser(user);
    handleEditUserOpen();
  }

  async function handleDepartmentViewClick(department) {
    setSelectedDepartment(department);
    handleCreateDepartmentOpen();
  }

  async function handleTeamViewClick(team) {
    setSelectedTeam(team);
    handleCreateTeamOpen();
  }

  const renderAdminMenu = () => {
    return (
      <>
        {/* Grey backdrop during modals */}
        <Backdrop open={adminMenuOpen} sx={{ zIndex: 1000 }} />
        {/* + Modals */}
        <SpeedDial
          ariaLabel="Admin menu"
          icon={<SpeedDialIcon />}
          onClick={adminMenuOpen ? handleAdminMenuClose : handleAdminMenuOpen}
          open={adminMenuOpen}
          direction="down"
          sx={{ position: 'absolute', '& .MuiSpeedDialAction-staticTooltipLabel': { width: 'max-content' } }}
        >
          <SpeedDialAction
            key="Register New User"
            icon={<PersonAddIcon />}
            tooltipTitle="Register New User"
            tooltipOpen
            onClick={handleCreateUserOpen}
          />
          <SpeedDialAction
            key="Create a Department"
            icon={<WorkIcon />}
            tooltipTitle="Create a Department"
            tooltipOpen
            onClick={handleCreateDepartmentOpen}
          />
          <SpeedDialAction
            key="Create a Team"
            icon={<GroupAddIcon />}
            tooltipTitle="Create a Team"
            tooltipOpen
            onClick={handleCreateTeamOpen}
          />
        </SpeedDial>
        {createDepartmentOpen ? (
          <CreateDepartmentFormModal
            open={createDepartmentOpen}
            handleCreateDepartmentClose={handleCreateDepartmentClose}
            department={selectedDepartment}
          />
        ) : (
          <></>
        )}
        {createTeamOpen ? (
          <CreateTeamFormModal
            open={createTeamOpen}
            handleCreateTeamClose={handleCreateTeamClose}
            team={selectedTeam}
          />
        ) : (
          <></>
        )}
        {editUserOpen ? (
          <EditUserFormModal open={editUserOpen} handleEditUserClose={handleEditUserClose} user={selectedUser} />
        ) : (
          <></>
        )}
        {createUserOpen ? (
          <CreateUserFormModal open={createUserOpen} handleCreateUserClose={handleCreateUserClose} />
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <div className="Admin">
      <Box sx={{ marginTop: '2%' }}>
        <Typography variant="h4">Admin</Typography>
        <Divider />
      </Box>
      <Box sx={{ marginTop: '2%' }}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Typography variant="h5" sx={{ '& .MuiLink-root': { padding: '0.5%' } }}>
              <Link underline={menu === 'users' ? 'always' : 'none'} href="#" onClick={loadUserTable}>
                Users
              </Link>
              <Link underline={menu === 'departments' ? 'always' : 'none'} href="#" onClick={loadDepartmentTable}>
                Departments
              </Link>
              <Link underline={menu === 'teams' ? 'always' : 'none'} href="#" onClick={loadTeamsTable}>
                Teams
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {renderAdminMenu()}
          </Grid>
          <Grid item xs={12}>
            {!isLoading && tableData ? (
              <ContentTable headers={tableHeaders} rows={tableDataRef.current} />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

Admin.propTypes = {
  isAuthenticated: PropTypes.bool,
  history: PropTypes.object,
};
