/* eslint-disable no-unused-vars */
import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { getAllDepartments } from '../../apis/department-api';
import { getTeamsByDepartment } from '../../apis/teams-api';
import { getUser } from '../../apis/user-api';
import { CalendarTimeline } from '../../components/CalendarTimeline';
import Dropdown from '../../components/input/Dropdown';
import { calculateCurrentPeriod, getPeriodOption, getStartEndDatesForPeriod } from '../../lib/helpers';

export default function Calendar(props) {
  const [visibleCalendarStart, setVisibleCalendarStart] = useState(null);
  const [visibleCalendarEnd, setVisibleCalendarEnd] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [availableDepartments, setAvailableDepartments] = useState(null);
  const [availableTeams, setAvailableTeams] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(false);
  const [availablePeriods, setPeriods] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const mobileRef = useRef(isMobile);

  function handleWindowSizeChange() {
    if (mobileRef.current && window.innerWidth > 768) {
      setIsLoading(true);
      mobileRef.current = false;
      setIsMobile(false);
    } else if (!mobileRef.current && window.innerWidth <= 768) {
      setIsLoading(true);
      mobileRef.current = true;
      setIsMobile(true);
    }
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      const currentUser = await getUser(props.authenticatedUser.attributes.email);
      setSelectedDepartment(currentUser.departmentId);
      setSelectedTeam(currentUser.teamId);
      const availablePeriods = getPeriodOption(2);
      setPeriods(availablePeriods);

      setSelectedPeriod(availablePeriods[2]);
      setAvailableDepartments(await getAllDepartments());
    }
    async function updateTimeline() {
      const period = calculateCurrentPeriod(new Date());
      setSelectedPeriod(period);
      updateCalendarTimeline(period);
    }
    if (isLoading && selectedDepartment === null) {
      onLoad();
    }

    updateTimeline();
    setIsLoading(false);
  }, [isLoading, isMobile]);

  useEffect(() => {
    async function onDepartmentChange() {
      setIsLoading(true);
      if (!selectedDepartment || selectedDepartment === -1) {
        setAvailableTeams(null);
      } else {
        const teams = await getTeamsByDepartment(selectedDepartment);
        setAvailableTeams(teams);
      }
      setIsLoading(false);
    }
    onDepartmentChange();
  }, [selectedDepartment]);

  function updateCalendarTimeline(period) {
    const periodDates = getStartEndDatesForPeriod(period);

    const today = moment(new Date());
    const visibleStartDate =
      today >= moment(periodDates.startDate, 'DD/MM/YYYY') && today <= moment(periodDates.endDate, 'DD/MM/YYYY')
        ? moment(today, 'DD/MM/YYYY').add(-7, 'day').format('DD/MM/YYYY')
        : moment(periodDates.startDate, 'DD/MM/YYYY').format('DD/MM/YYYY');

    const visibleEndDate = moment(visibleStartDate, 'DD/MM/YYYY')
      .add(1, `${mobileRef.current ? 'week' : 'month'}`)
      .subtract(1, 'milliseconds')
      .format('DD/MM/YYYY');

    setVisibleCalendarStart(moment(visibleStartDate, 'DD/MM/YYYY'));
    setVisibleCalendarEnd(moment(visibleEndDate, 'DD/MM/YYYY'));
  }

  function handlePeriodChange(event) {
    setSelectedPeriod(event.target.value);
    updateCalendarTimeline(event.target.value);
  }

  function handleDepartmentChange(event) {
    setSelectedDepartment(event.target.value);
    setSelectedTeam('-1');
  }

  function handleTeamChange(event) {
    setSelectedTeam(event.target.value);
  }

  return (
    <div>
      <Box sx={{ marginTop: '2%' }}>
        <Typography variant="h4">Department Calendar</Typography>
        <Divider />
      </Box>
      <div>
        {isLoading ? (
          <>
            <CircularProgress />
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4} s={3} md={3}>
                <Dropdown
                  placeholder="Select Department"
                  data={availableDepartments}
                  selectedValueField="departmentId"
                  selectedTextField="departmentName"
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                />
              </Grid>
              <Grid item xs={4} s={3} md={3}>
                <Dropdown
                  placeholder="Select Team"
                  data={availableTeams}
                  selectedValueField="teamId"
                  selectedTextField="teamName"
                  value={selectedTeam}
                  emptySelect="All Teams"
                  onChange={handleTeamChange}
                />
              </Grid>
              <Grid item xs={1} s={4} md={4}></Grid>
              <Grid item xs={3} s={2} md={2}>
                <div className="calendar-dropdown-container">
                  <Dropdown
                    placeholder={selectedPeriod}
                    data={availablePeriods}
                    value={selectedPeriod}
                    onChange={handlePeriodChange}
                  />
                </div>
              </Grid>
            </Grid>

            <div>
              <CalendarTimeline
                visibleTimeStart={visibleCalendarStart ? visibleCalendarStart : new Date()}
                visibleTimeEnd={visibleCalendarEnd}
                currentUserEmail={props.authenticatedUser ? props.authenticatedUser.attributes.email : ''}
                selectedPeriod={selectedPeriod}
                selectedDepartment={selectedDepartment}
                selectedTeam={selectedTeam}
              ></CalendarTimeline>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

Calendar.propTypes = {
  authenticatedUser: PropTypes.object,
};
