import { API } from 'aws-amplify';

export async function getBankHolidays(startDate, endDate) {
  let request = {
    queryStringParameters: {
      startDate: startDate.toString(),
      endDate: endDate.toString(),
    },
  };

  return API.get('vacationStationAPI', '/bank-holidays', request);
}
