import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Card, CardContent, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-business-days';
import { getPendingRequests } from '../../apis/request-api';
import { requestActions } from '../../lib/enums/request-actions';
import { handleRequestActionButtonClick } from '../../lib/request-action-helper';

export default function Pending(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState(null);
  const pendingRequestsRef = useRef(requests);

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      const pendingRequests = await getMyPendingRequests();
      setRequests(pendingRequests);
      pendingRequestsRef.current = pendingRequests;
      setIsLoading(false);
    }
    onLoad();
  }, []);

  async function getMyPendingRequests() {
    let data = await getPendingRequests(props.authenticatedUser.attributes.email);
    return data.map((request) => {
      return {
        requestor: request.userName,
        startDate: new moment(request.startDate).utc().format('ddd Do MMM YYYY'),
        endDate: new moment(request.endDate).utc().format('ddd Do MMM YYYY'),
        fullAMPM: request.fullAMPM,
        notes: request.comment,
        status: request.status,
        button: (
          <Grid container spacing={2} justify="center">
            <Grid item xs={6} align="center">
              <Button
                sx={{ margin: '0 5px' }}
                fullWidth
                endIcon={<CheckIcon />}
                variant="contained"
                color="success"
                onClick={async () => {
                  await requestActionButtonClick(
                    'Approve',
                    requestActions.APPROVE,
                    request.emailAddress,
                    request.startDate,
                    request.requestId,
                    request.endDate,
                    request.userName,
                    request.fullAMPM,
                  );
                }}
              >
                Approve
              </Button>
            </Grid>
            <Grid item xs={6} align="center">
              <Button
                sx={{ margin: '0 5px' }}
                fullWidth
                endIcon={<ClearIcon />}
                variant="contained"
                color="error"
                onClick={async () => {
                  await requestActionButtonClick(
                    'Reject',
                    requestActions.REJECT,
                    request.emailAddress,
                    request.startDate,
                    request.requestId,
                    request.endDate,
                    request.userName,
                    request.fullAMPM,
                  );
                }}
              >
                Reject
              </Button>
            </Grid>
          </Grid>
        ),
      };
    });
  }

  async function requestActionButtonClick(
    requestAction,
    status,
    userEmail,
    startDate,
    requestId,
    endDate,
    requestorName,
    fullAMPM,
  ) {
    await handleRequestActionButtonClick(
      requestAction,
      status,
      userEmail,
      startDate,
      requestId,
      endDate,
      requestorName,
      fullAMPM,
    );
    setIsLoading(true);
    const pendingRequests = await getMyPendingRequests();
    setRequests(pendingRequests);
    pendingRequestsRef.current = pendingRequests;
    setIsLoading(false);
  }

  const handleCards = () => {
    let requestCards = [];
    pendingRequestsRef.current.forEach((request) => {
      requestCards.push(
        <Grid item xs={12} s={6} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center" sx={{ textTransform: 'capitalize' }}>
                {request.requestor}
              </Typography>
              <Divider />
              <Grid container>
                <Grid item xs={6}>
                  <Typography>Start: {request.startDate}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>End: {request.endDate}</Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={{ textTransform: 'capitalize' }}>
                    Holiday Type: {request.fullAMPM.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ textTransform: 'capitalize' }}>Status: {request.status}</Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container>
                <Grid item xs={12} sx={{ padding: '3%' }}>
                  Notes: {request.notes.length > 0 ? request.notes : 'No reason specified'}
                </Grid>
              </Grid>
              <Divider sx={{ marginBottom: '3%' }} />
              {request.button !== '' ? <>{request.button}</> : <> </>}
            </CardContent>
          </Card>
        </Grid>,
      );
    });

    return requestCards;
  };

  function renderRequests() {
    return (
      <div className="requests">
        <Box sx={{ marginTop: '2%', marginBottom: '2%' }}>
          <Typography variant="h4">Pending Requests</Typography>
          <Divider />
        </Box>

        {!isLoading && requests ? (
          requests.length > 0 ? (
            <Grid
              container
              spacing={2}
              sx={{ marginBottom: '20%', '& .MuiTypography-body1': { padding: '4%', textAlign: 'center' } }}
            >
              {handleCards()}
            </Grid>
          ) : (
            <Typography variant="h5" paddingTop="1%">
              No pending requests.
            </Typography>
          )
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }

  return <div className="Pending">{renderRequests()}</div>;
}

Pending.propTypes = {
  authenticatedUser: PropTypes.object,
  history: PropTypes.object,
};
