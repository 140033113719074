import { AppBar, Link, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import logo from './ino_logo.png';

export default function NavBar(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  function toggleDrawer() {
    setDrawerOpen(drawerOpen === true ? false : true);
  }

  function handleLogout() {
    toggleDrawer();
    props.handleLogout();
  }

  function renderLinks() {
    const links = [];
    if (props.isAuthenticated) {
      links.push(
        <ListItem>
          <ListItemIcon>
            <PermContactCalendarIcon />
          </ListItemIcon>
          <Link key="myrequests" color="inherit" underline="none" href="/myrequests">
            My Requests
          </Link>
        </ListItem>,
        <ListItem>
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <Link key="calendar" color="inherit" underline="none" href="/calendar">
            Calendar
          </Link>
        </ListItem>,
      );
    }
    if (props.isApprover) {
      links.push(
        <ListItem>
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <Link key="pending" color="inherit" underline="none" href="/pending">
            Pending Requests
          </Link>
        </ListItem>,
      );
    }
    if (props.isAdmin) {
      links.push(
        <ListItem>
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <Link key="admin" color="inherit" underline="none" href="/admin">
            Admin
          </Link>
        </ListItem>,
      );
    }
    if (props.isAuthenticated) {
      links.push(
        <ListItem>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <Link key="logout" color="inherit" underline="none" onClick={handleLogout} href="#">
            Logout {props.userName}
          </Link>
        </ListItem>,
      );
    }

    return links;
  }

  return (
    <AppBar sx={{ backgroundColor: '#C8102E', position: 'static' }}>
      <Toolbar variant="dense">
        {props.isAuthenticated ? (
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        ) : (
          <> </>
        )}

        <Typography sx={{ flexGrow: 1 }} variant="h3">
          <Link color="inherit" underline="none" href="/">
            <img
              src={logo}
              style={{
                verticalAlign: 'bottom',
                display: 'inline-block',
                position: 'relative',
                width: '125px',
              }}
            ></img>
          </Link>
        </Typography>
        <Typography
          sx={{
            '& a': {
              margin: '0 8px',
              color: 'inherit',
            },
          }}
        >
          {props.isAuthenticated ? (
            <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer}>
              <List>{renderLinks()}</List>
            </Drawer>
          ) : (
            <> </>
          )}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

NavBar.propTypes = {
  isAuthenticated: PropTypes.bool,
  isApprover: PropTypes.bool,
  isAdmin: PropTypes.bool,
  handleLogout: PropTypes.func,
  userName: PropTypes.string,
};
