import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Fab,
  Grid,
  Modal,
  Typography,
  Divider,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { getTeamsByDepartment } from '../../../apis/teams-api';
import { getAllDepartments } from '../../../apis/department-api';
import { updateUser } from '../../../apis/user-api';
import { getEntitlement } from '../../../apis/entitlement-api';
import { calculateCurrentPeriod } from '../../../lib/helpers';
import * as sweetAlertService from '../../../lib/sweet-alert-service';

const validationSchema = yup.object({
  name: yup.string().required('Please enter a Name'),
  departmentId: yup.string().required().min(4, 'Please select a Department.'),
  teamId: yup.string().required().min(4, 'Please select a Team.'),
  emailAddress: yup.string().email().required('Please enter a valid email address'),
  currentAnnualEntitlement: yup.number().required().min(0, 'Entitlement cannot be less than 0'),
  currentRemainingEntitlement: yup.number().required().min(0, 'Entitlement cannot be less than 0'),
  nextPeriodAnnualEntitlement: yup.number().required().min(0, 'Entitlement cannot be less than 0'),
  nextPeriodRemainingEntitlement: yup.number().required().min(0, 'Entitlement cannot be less than 0'),
});

export default function EditUserFormModal({ open, handleEditUserClose, user }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(user.departmentId);
  const [availableDepartments, setAvailableDepartments] = useState(null);
  const [availableTeams, setAvailableTeams] = useState([]);
  const [currentPeriodEntitlement, setCurrentPeriodEntitlement] = useState(null);
  const [nextPeriodEntitlement, setNextYearEntitlement] = useState(null);

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      setAvailableDepartments(await getAllDepartments());
      if (user) {
        const teams = await getTeamsByDepartment(user.departmentId);
        setAvailableTeams(teams);
        const currentPeriod = calculateCurrentPeriod(new Date());
        const nextPeriod = (Number(currentPeriod.substr(0, 4)) + 1).toString();
        setCurrentPeriodEntitlement(await getEntitlement(user.emailAddress, currentPeriod));
        setNextYearEntitlement(await getEntitlement(user.emailAddress, nextPeriod));
      }
      setIsLoading(false);
    }
    onLoad();
  }, []);

  useEffect(() => {
    async function onDepartmentChange() {
      if (!selectedDepartment || selectedDepartment === -1) {
        setAvailableTeams(null);
      } else {
        const teams = await getTeamsByDepartment(selectedDepartment);
        setAvailableTeams(teams);
      }
    }
    onDepartmentChange();
  }, [selectedDepartment]);

  const initialFormValues = {
    name: user.name,
    emailAddress: user.emailAddress,
    departmentId: user.departmentId,
    teamId: user.teamId,
    isApprover: user.isApprover,
    isAdmin: user.isAdmin,
    isDisabled: user.isDisabled,
    currentAnnualEntitlement: currentPeriodEntitlement?.total,
    currentRemainingEntitlement: currentPeriodEntitlement?.remaining,
    nextPeriodAnnualEntitlement: nextPeriodEntitlement ? nextPeriodEntitlement.total : 0,
    nextPeriodRemainingEntitlement: nextPeriodEntitlement ? nextPeriodEntitlement.remaining : 0,
  };

  const onSubmit = async (values) => {
    const body = {
      name: values.name,
      departmentId: values.departmentId,
      teamId: values.teamId,
      isApprover: values.isApprover,
      isAdmin: values.isAdmin,
      isDisabled: values.isDisabled,
      entitlement: {
        currentPeriodEntitlement: {
          holidayPeriod: user ? currentPeriodEntitlement.holidayPeriod : calculateCurrentPeriod(new Date()),
          total: values.currentAnnualEntitlement,
          remaining: values.currentRemainingEntitlement,
        },
        nextPeriodEntitlement: {
          holidayPeriod: user
            ? nextPeriodEntitlement.holidayPeriod
            : (Number(currentPeriodEntitlement.holidayPeriod) + 1).toString(),
          total: values.nextPeriodAnnualEntitlement,
          remaining: values.nextPeriodRemainingEntitlement,
        },
      },
    };

    try {
      await updateUser(body, values.emailAddress);
      sweetAlertService.alertPopup(
        'Success',
        `${user.name} has been updated successfully`,
        sweetAlertService.sweetAlertPopupType.SUCCESS,
      );
    } catch (error) {
      sweetAlertService.alertPopup(
        'Failure',
        `Failed to update ${user.name}`,
        sweetAlertService.sweetAlertPopupType.ERROR,
      );
    }

    handleEditUserClose();
  };

  return (
    <Modal open={open} onClose={handleEditUserClose} sx={{ zIndex: 1000 }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        {!isLoading && availableDepartments ? (
          <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, touched, errors, handleChange, isValid, setFieldValue }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    '& .MuiFormControl-root': {
                      width: '100% !important',
                    },
                  }}
                >
                  <Grid item xs={11} sm={11} md={11}>
                    <Typography component="h1" variant="h5">
                      {user ? user.name : 'Create New User'}
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid item xs={1} sm={1} md={1}>
                    <Fab
                      color="primary"
                      aria-label="add"
                      onClick={handleEditUserClose}
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: '2.5px',
                        right: '2.5px',
                      }}
                    >
                      <CloseIcon />
                    </Fab>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="name"
                      name="name"
                      label="Name"
                      variant="standard"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="departmentId"
                      name="departmentId"
                      label="Department"
                      select
                      variant="standard"
                      value={values.departmentId}
                      onChange={(event) => {
                        values.departmentId = event.target.value;
                        setSelectedDepartment(event.target.value);
                      }}
                      fullWidth
                      error={touched.departmentId && !!errors.departmentId}
                      helperText={touched.departmentId && errors.departmentId}
                    >
                      <MenuItem value="-1">
                        <em>Please select a Department</em>
                      </MenuItem>
                      {availableDepartments.map((department) => (
                        <MenuItem key={department.departmentId} value={department.departmentId}>
                          {department.departmentName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="teamId"
                      name="teamId"
                      label="Team"
                      select
                      variant="standard"
                      value={values.teamId}
                      onChange={handleChange}
                      fullWidth
                      error={touched.teamId && !!errors.teamId}
                      helperText={touched.teamId && errors.teamId}
                    >
                      <MenuItem value="-1">
                        <em>Please select a Team</em>
                      </MenuItem>
                      {availableTeams.map((team) => (
                        <MenuItem key={team.teamId} value={team.teamId}>
                          {team.teamName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="emailAddress"
                      name="emailAddress"
                      label="Email"
                      variant="standard"
                      value={values.emailAddress}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Typography>Entitlement</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Typography>
                      {user && currentPeriodEntitlement
                        ? currentPeriodEntitlement.holidayPeriod
                        : calculateCurrentPeriod()}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          id="currentAnnualEntitlement"
                          name="currentAnnualEntitlement"
                          label="Annual"
                          variant="standard"
                          type="number"
                          value={values.currentAnnualEntitlement}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="currentRemainingEntitlement"
                          name="currentRemainingEntitlement"
                          label="Remaining"
                          variant="standard"
                          type="number"
                          value={values.currentRemainingEntitlement}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Typography>
                      {user && nextPeriodEntitlement ? nextPeriodEntitlement.holidayPeriod : 'Default Entitlement'}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={6}>
                        <TextField
                          id="nextPeriodAnnualEntitlement"
                          name="nextPeriodAnnualEntitlement"
                          label="Annual"
                          variant="standard"
                          type="number"
                          value={values.nextPeriodAnnualEntitlement}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="nextPeriodRemainingEntitlement"
                          name="nextPeriodRemainingEntitlement"
                          label="Remaining"
                          variant="standard"
                          type="number"
                          value={values.nextPeriodRemainingEntitlement}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          label="Administrator"
                          labelPlacement="end"
                          control={
                            <Checkbox
                              name="isAdmin"
                              checked={values.isAdmin}
                              onChange={(event) => {
                                setFieldValue('isAdmin', event.target.checked);
                              }}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          label="Approver"
                          labelPlacement="end"
                          control={
                            <Checkbox
                              name="isApprover"
                              checked={values.isApprover}
                              onChange={(event) => {
                                setFieldValue('isApprover', event.target.checked);
                              }}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
                      Save Changes
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          label="Disabled"
                          labelPlacement="end"
                          control={
                            <Checkbox
                              name="isDisabled"
                              checked={values.isDisabled}
                              onChange={(event) => {
                                setFieldValue('isDisabled', event.target.checked);
                              }}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Modal>
  );
}

EditUserFormModal.propTypes = {
  open: PropTypes.bool,
  handleEditUserClose: PropTypes.func,
  user: PropTypes.object,
};
