const dev = {
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://api.dev.cef-ino.co.uk',
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_tNhC61yhd',
    APP_CLIENT_ID: '3unp6mt4id73g0fn5r2if620oc',
    IDENTITY_POOL_ID: 'eu-west-1:25e6faf6-8ce3-41ea-b2b1-cf49122b99e5',
  },
};

const devtest = {
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://opdljewr2m.execute-api.eu-west-1.amazonaws.com/dev',
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_jy07BhXIO',
    APP_CLIENT_ID: '66rupp2tpmdhmp95rt47h6v4ta',
    IDENTITY_POOL_ID: 'eu-west-1:dc683c1b-fd7b-4ee9-ab63-6e42ef8bd259',
  },
};

const test = {
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://api.test.cef-ino.co.uk',
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_SXuCnW9Ws',
    APP_CLIENT_ID: '43i4pokpi8306aqq5qqcn8rdcb',
    IDENTITY_POOL_ID: 'eu-west-1:ae8fec67-37ae-4196-9bf4-5d1aef1df05b',
  },
};

const prod = {
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://api.cef-ino.co.uk',
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_Lv5CGZbSB',
    APP_CLIENT_ID: '4d1n42u79qqphaibt7fmv6n3nt',
    IDENTITY_POOL_ID: 'eu-west-1:cfc66820-8516-47d1-b134-3fcb1a6e4b2f',
  },
};

const config =
  process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : process.env.REACT_APP_STAGE === 'devtest'
    ? devtest
    : process.env.REACT_APP_STAGE === 'test'
    ? test
    : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
