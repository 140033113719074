import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Fab, Grid, Modal, TextField, Typography, MenuItem, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import * as sweetAlertService from '../../../lib/sweet-alert-service';
import { getAllDepartments } from '../../../apis/department-api';
import { createTeam, updateTeam, deleteTeam } from '../../../apis/teams-api';
import { getApprovers } from '../../../apis/user-api';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const validationSchema = yup.object({
  departmentId: yup.string('Please select a Department.').min(4, 'Please select a Department.'),
  teamName: yup.string('Please enter a Team name.').required('Please enter a Team name.'),
  managerEmail: yup.string().email('Please select a manager for this team.'),
});

export default function CreateTeamFormModal({ open, handleCreateTeamClose, team }) {
  const [isLoading, setIsLoading] = useState(false);
  const [availableDepartments, setAvailableDepartments] = useState([]);
  const [availableManagers, setAvailableManagers] = useState([]);

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      setAvailableDepartments(await getAllDepartments());
      setAvailableManagers(await getApprovers());
      setIsLoading(false);
    }
    onLoad();
  }, []);

  const onSubmit = async (values) => {
    const body = {
      departmentId: values.departmentId,
      teamName: values.teamName,
      managerEmail: values.managerEmail,
    };

    if (team) {
      body.teamId = team.teamId;
    }

    try {
      team ? await updateTeam(body) : await createTeam(body);
      sweetAlertService.alertPopup(
        'Success',
        'Team successfully created/updated',
        sweetAlertService.sweetAlertPopupType.SUCCESS,
      );
    } catch (error) {
      sweetAlertService.alertPopup(
        'Failure',
        'Team failed to create/update',
        sweetAlertService.sweetAlertPopupType.ERROR,
      );
    }

    handleCreateTeamClose();
  };

  const onDelete = async () => {
    const userConfirmed = await sweetAlertService.userConfirmationPopup(
      'Delete Team',
      `Are you sure you wish to delete the ${team.teamName} team?`,
    );

    if (userConfirmed) {
      try {
        await deleteTeam(team.departmentId, team.teamId);
        sweetAlertService.alertPopup(
          'Team Delete Success',
          `${team.teamName} has been successfully deleted`,
          sweetAlertService.sweetAlertPopupType.SUCCESS,
        );
      } catch (error) {
        sweetAlertService.alertPopup(
          'Team Delete Failure',
          `Failed to delete the ${team.teamName} team. You can only delete empty teams.`,
          sweetAlertService.sweetAlertPopupType.ERROR,
        );
      }
      handleCreateTeamClose();
    }
  };

  return (
    <Modal open={open} onClose={handleCreateTeamClose} sx={{ zIndex: 1000 }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        {!isLoading && availableDepartments ? (
          <Formik
            initialValues={{
              departmentId: team ? team.departmentId : '-1',
              teamName: team ? team.teamName : '',
              managerEmail: team && team.managerEmail ? team.managerEmail : '-1',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, touched, errors, handleChange }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    '& .MuiFormControl-root': {
                      width: '100% !important',
                    },
                  }}
                >
                  <Grid item xs={11} sm={11} md={11}>
                    <Typography component="h1" variant="h5">
                      {team ? 'Edit' : 'Create'} Team
                    </Typography>
                  </Grid>

                  <Grid item xs={1} sm={1} md={1}>
                    <Fab
                      color="primary"
                      aria-label="add"
                      onClick={handleCreateTeamClose}
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: '2.5px',
                        right: '2.5px',
                      }}
                    >
                      <CloseIcon />
                    </Fab>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="departmentId"
                      name="departmentId"
                      label="Department"
                      select
                      variant="standard"
                      value={values.departmentId}
                      onChange={handleChange}
                      fullWidth
                      error={touched.departmentId && !!errors.departmentId}
                      helperText={touched.departmentId && errors.departmentId}
                      disabled={team ? true : false}
                    >
                      <MenuItem value="-1">
                        <em>Please select a Department</em>
                      </MenuItem>
                      {availableDepartments?.map((department) => (
                        <MenuItem key={department.departmentId} value={department.departmentId}>
                          {department.departmentName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="managerEmail"
                      name="managerEmail"
                      label="Manager"
                      select
                      variant="standard"
                      value={values.managerEmail}
                      onChange={handleChange}
                      fullWidth
                      error={touched.managerEmail && !!errors.managerEmail}
                      helperText={touched.managerEmail && errors.managerEmail}
                    >
                      <MenuItem value="-1">
                        <em>Please select a Manager</em>
                      </MenuItem>
                      {availableManagers?.map((approver) => (
                        <MenuItem key={approver.emailAddress} value={approver.emailAddress}>
                          {approver.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="teamName"
                      name="teamName"
                      label="Team Name"
                      variant="standard"
                      value={values.teamName}
                      onChange={handleChange}
                      error={touched.teamName && !!errors.teamName}
                      helperText={touched.teamName && errors.teamName}
                    />
                  </Grid>

                  {team ? (
                    <>
                      <Grid item xs={12} sm={6} md={6}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={values.teamName.length <= 0}
                          endIcon={<CheckIcon />}
                        >
                          Submit
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Button
                          variant="contained"
                          color="error"
                          disabled={values.teamName.length <= 0}
                          endIcon={<ClearIcon />}
                          onClick={onDelete}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} sm={4}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={values.teamName.length <= 0}
                        endIcon={<CheckIcon />}
                      >
                        Submit
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Modal>
  );
}

CreateTeamFormModal.propTypes = {
  open: PropTypes.bool,
  handleCreateTeamClose: PropTypes.func,
  team: PropTypes.object,
};
