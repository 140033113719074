import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Card, CardContent, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-business-days';
import { getMyRequests } from '../../apis/request-api';
import { Dropdown } from '../../components/input/Dropdown';
import { requestActions } from '../../lib/enums/request-actions';
import { calculateCurrentPeriod, getPeriodOption } from '../../lib/helpers';
import { handleRequestActionButtonClick } from '../../lib/request-action-helper';
import Summary from './Summary';

export default function MyRequests(props) {
  const [period, setPeriod] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [periods, setPeriods] = useState(null);
  const requestsRef = useRef(requests);

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      setPeriods(getPeriodOption(2));
      const defaultPeriod = calculateCurrentPeriod(new Date());
      setPeriod(defaultPeriod);
      const userRequests = await getUserRequests(defaultPeriod);
      setRequests(userRequests);
      requestsRef.current = userRequests;
      setIsLoading(false);
    }
    onLoad();
  }, [props.userDetails]);

  useEffect(() => {
    async function onReload() {
      if (isLoading) {
        const userRequests = await getUserRequests(period);
        setRequests(userRequests);
        requestsRef.current = userRequests;
        setIsLoading(false);
      }
    }
    onReload();
  }, [isLoading]);

  async function getUserRequests(period) {
    const userEmail = props.currentUserEmailAddress ? props.currentUserEmailAddress : props.userDetails?.emailAddress;
    let userRequests = await getMyRequests(userEmail, period);
    userRequests = userRequests ? userRequests : [];
    return userRequests.map((request) => {
      return {
        startDate: new moment(request.startDate).utc().format('ddd Do MMM YYYY'),
        endDate: new moment(request.endDate).utc().format('ddd Do MMM YYYY'),
        fullAMPM: request.fullAMPM,
        status: request.status,
        notes: request.comment,
        approverComment: request.approverComment,
        button:
          request.status === 'pending' || request.status === 'approved' ? (
            <Button
              endIcon={<ClearIcon />}
              variant="contained"
              color="error"
              fullWidth
              onClick={async () => {
                await requestActionButtonClick(request.emailAddress, request.startDate, request.requestId);
              }}
            >
              Cancel
            </Button>
          ) : (
            ''
          ),
      };
    });
  }

  async function handlePeriodChange(event) {
    setPeriod(event.target.value);
    setIsLoading(true);
  }

  async function requestActionButtonClick(userEmail, startDate, requestId) {
    await handleRequestActionButtonClick('Cancel', requestActions.CANCEL, userEmail, startDate, requestId);
    setIsLoading(true);
  }

  const handleCards = () => {
    let requestCards = [];
    requestsRef.current.forEach((request) => {
      requestCards.push(
        <Grid item xs={12} s={6} md={6}>
          <Card>
            <CardContent sx={{ textTransform: 'capitalize' }}>
              <Typography variant="h6" align="center">
                {request.fullAMPM !== 'full' || request.startDate === request.endDate
                  ? request.startDate
                  : `${request.startDate} - ${request.endDate}`}
              </Typography>
              <Divider />
              <Grid container>
                <Grid item xs={6}>
                  <Typography>Holiday Type: {request.fullAMPM.toUpperCase()}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Status: {request.status}</Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container>
                <Grid item xs={12} sx={{ padding: '3%' }}>
                  Notes: {request.notes.length > 0 ? request.notes : 'No reason specified'}
                </Grid>
                {request.approverComment ? (
                  <Grid item xs={12} sx={{ padding: '0% 3% 0% 3%' }}>
                    Manager Comment: {request.approverComment}
                  </Grid>
                ) : null}
              </Grid>
              {request.button !== '' ? (
                <>
                  <Divider sx={{ marginBottom: '3%' }} />
                  <Grid container alignItems="center" justifyContent="center" direction="column">
                    <Grid item xs={12}>
                      {request.button}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <> </>
              )}
            </CardContent>
          </Card>
        </Grid>,
      );
    });

    return requestCards;
  };

  return (
    <div>
      <Box sx={{ marginTop: '2%' }}>
        <Typography variant="h4">My Requests</Typography>
        <Divider />
      </Box>
      {!isLoading && requests ? (
        <Grid
          container
          spacing={2}
          sx={{ marginBottom: '15%', '& .MuiTypography-body1': { padding: '4%', textAlign: 'center' } }}
        >
          <Grid item xs={7} s={6} md={7}></Grid>
          <Grid item xs={5} s={3} md={2}>
            <Dropdown placeholder={period} data={periods} value={period} onChange={handlePeriodChange} />
          </Grid>
          <Grid item xs={12} s={12} md={9}>
            {requests.length > 0 ? (
              <Grid container spacing={2}>
                {handleCards()}
              </Grid>
            ) : (
              <>
                <Typography variant="h5" paddingTop="1%">
                  You have no requests for {period}.
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} s={12} md={3}>
            <Summary email={props.authenticatedUser.attributes.email} period={period} />
          </Grid>
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

MyRequests.propTypes = {
  authenticatedUser: PropTypes.object,
  userDetails: PropTypes.object,
  currentUserEmailAddress: PropTypes.string,
};
