import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Button, Fab, Grid, MenuItem, Modal, TextField, Typography, CircularProgress } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment-business-days';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { getEntitlement } from '../../apis/entitlement-api';
import { calculateRequestCost, createRequest } from '../../apis/request-api';
import { getApprovers } from '../../apis/user-api';
import { getBankHolidays } from '../../apis/working-days-api';
import { calculateCurrentPeriod, getStartEndDatesForPeriod } from '../../lib/helpers';
import * as sweetAlertService from '../../lib/sweet-alert-service';

const startDate = new Date();
const endDate = new Date();
const holidayType = 'full';

const initialFormValues = {
  startDate,
  endDate,
  reason: '',
  approver: '-1',
  holidayType,
};
export default function CreateRequestFormModal({ currentUserEmail, open, handleClose, isApprover }) {
  const [isLoading, setIsLoading] = useState(false);
  const [period, setPeriod] = useState(calculateCurrentPeriod(startDate));
  const [remainingEntitlement, setRemainingEntitlement] = useState(null);
  const [workingDays, setWorkingDays] = useState(0);
  const [maxEndDate, setMaxEndDate] = useState(undefined);
  const [bankHolidays, setBankHolidays] = useState([]);
  const [availableApprovers, setAvailableApprovers] = useState(null);
  const [formStartDate, setFormStartDate] = useState(startDate);
  const [formEndDate, setFormEndDate] = useState(endDate);
  const formEndDateRef = useRef(formEndDate);

  useEffect(() => {
    async function calculateWorkingDaysOnLoad() {
      await calculateWorkingDays(startDate, endDate, holidayType);
    }
    calculateWorkingDaysOnLoad();

    async function fetchBankHolidays() {
      const periodDates = await getStartEndDatesForPeriod(period);
      setBankHolidays(await getBankHolidays(periodDates.startDate, periodDates.endDate));
    }
    fetchBankHolidays();

    async function fetchApprovers() {
      const approvers = await getApprovers();
      setAvailableApprovers(approvers.filter((user) => user.emailAddress != currentUserEmail));
    }
    fetchApprovers();
  }, []);

  useEffect(() => {
    async function fetchEntitlement() {
      const entitlement = await getEntitlement(currentUserEmail, period);
      setRemainingEntitlement(entitlement.remaining);
    }
    fetchEntitlement();
  }, [period]);

  useEffect(() => {
    async function fetchMaxEndDate() {
      setMaxEndDate(await calculateMaxEndDate(formStartDate, remainingEntitlement));
    }
    fetchMaxEndDate();
  }, [remainingEntitlement]);

  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  const calculateWorkingDays = async (startDate, endDate, holidayType) => {
    const utcStartDate = new moment(startDate, 'YYYY-MM-DDTHH:mm"').utc();
    const utcEndDate = new moment(endDate, 'YYYY-MM-DDTHH:mm"').utc();
    const numDays = await calculateRequestCost(utcStartDate.toISOString(), utcEndDate.toISOString(), holidayType);
    setWorkingDays(numDays.cost);
  };

  const calculateMaxEndDate = async (startDate, remainingEntitlement) => {
    startDate = new Date(startDate);
    startDate.setHours(0, 0, 0, 0);

    let holidays = [];
    if (bankHolidays.length > 0) {
      holidays = bankHolidays
        .filter((holiday) => {
          return new Date(holiday.date) >= startDate;
        })
        .map((holiday) => {
          return moment(new Date(holiday.date).toISOString()).format('DD-MM-YYYY');
        });
    }

    moment.updateLocale('uk', {
      holidays,
      holidayFormat: 'DD-MM-YYYY',
      workingWeekdays: [1, 2, 3, 4, 5],
    });

    // we need to round remainingEntitlement down in case we're currently on e.g. 3.5 holidays left
    // Math.floor(3.5) = 3, 3 - 1 = 2, 2021-01-01 + 2 = 2021-01-03 = 3 days holiday = 0.5 holidays remaining
    // 3.5 - 1 = 2.5, 2021-01-01 + 2.5 = 2021-01-04 12:00:00 = 4 days holiday = -0.5 holidays remaining
    return moment(startDate)
      .businessAdd(Math.floor(remainingEntitlement) - 1)
      .toDate();
  };

  const onSubmit = async (values) => {
    const utcStartDate = new moment(values.startDate, 'YYYY-MM-DDTHH:mm"').utc();
    const utcEndDate = new moment(values.endDate, 'YYYY-MM-DDTHH:mm"').utc();

    const body = {
      emailAddress: currentUserEmail,
      startDate: utcStartDate.toISOString(),
      endDate: values.holidayType === 'full' ? utcEndDate.toISOString() : utcStartDate.toISOString(),
      category: 'holiday',
      comment: values.reason,
      fullAMPM: values.holidayType,
    };

    if (isApprover) {
      body.managerEmail = values.approver;
    }

    try {
      await createRequest(body);
      sweetAlertService.alertPopup(
        'Success',
        'Your request has been successfully submitted',
        sweetAlertService.sweetAlertPopupType.SUCCESS,
      );
    } catch (error) {
      sweetAlertService.alertPopup(
        'Failure',
        'Failed to create request, please check the dates.',
        sweetAlertService.sweetAlertPopupType.ERROR,
      );
    }

    onClose();
  };

  const onClose = async () => {
    setWorkingDays(1);
    setMaxEndDate(undefined);
    setIsLoading(false);
    handleClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        {!isLoading ? (
          <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
            {({ values, handleChange, setFieldValue, isValid }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    '& .MuiFormControl-root': {
                      width: '100% !important',
                    },
                  }}
                >
                  <Grid item xs={11} sm={11} md={11}>
                    <Typography component="h1" variant="h5">
                      Create Request
                    </Typography>
                  </Grid>

                  <Grid item xs={1} sm={1} md={1}>
                    <Fab
                      color="primary"
                      aria-label="add"
                      onClick={onClose}
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: '2.5px',
                        right: '2.5px',
                      }}
                    >
                      <CloseIcon />
                    </Fab>
                  </Grid>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={12} sm={12} md={6}>
                      <DatePicker
                        shouldDisableDate={disableWeekends}
                        label="Start Date"
                        id="startDate"
                        name="startDate"
                        inputFormat="dd/MM/yyyy"
                        value={values.startDate}
                        onChange={async (value) => {
                          setFieldValue('startDate', value);
                          setFormStartDate(value);
                          const maxEndDate = await calculateMaxEndDate(value, remainingEntitlement);
                          // start date cannot be ahead of end date, and end date cannot be ahead of maxEndDate
                          if (value >= values.endDate || values.endDate > maxEndDate) {
                            setFieldValue('endDate', value);
                            setFormEndDate(value);
                            formEndDateRef.current = value;
                          }
                          setPeriod(calculateCurrentPeriod(value));
                          setMaxEndDate(maxEndDate);
                          if (value >= formEndDateRef.current) {
                            await calculateWorkingDays(value, value, values.holidayType);
                          } else {
                            await calculateWorkingDays(value, formEndDateRef.current, values.holidayType);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="holidayType"
                        name="holidayType"
                        label="Full or Half Day"
                        select
                        value={values.holidayType}
                        onChange={async (event) => {
                          setFieldValue('holidayType', event.target.value);
                          await calculateWorkingDays(values.startDate, values.endDate, event.target.value);
                        }}
                        fullWidth
                      >
                        <MenuItem value={'full'}>Full Day</MenuItem>
                        <MenuItem value={'am'}>Morning</MenuItem>
                        <MenuItem value={'pm'}>Afternoon</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <DatePicker
                        shouldDisableDate={disableWeekends}
                        label="End Date"
                        id="endDate"
                        name="endDate"
                        inputFormat="dd/MM/yyyy"
                        value={values.endDate}
                        onChange={async (value) => {
                          setFieldValue('endDate', value);
                          formEndDateRef.current = value;
                          await calculateWorkingDays(values.startDate, value, values.holidayType);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={values.startDate}
                        maxDate={maxEndDate}
                        disabled={values.holidayType !== 'full'}
                      />
                    </Grid>

                    {isApprover ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          id="approver"
                          name="approver"
                          label="Approver"
                          select
                          value={values.approver}
                          onChange={handleChange}
                          fullWidth
                        >
                          <MenuItem value="-1">
                            <em>Please select an approver</em>
                          </MenuItem>
                          {availableApprovers?.map((approver) => (
                            <MenuItem key={approver.name} value={approver.emailAddress}>
                              {approver.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="reason"
                        name="reason"
                        label="Reason"
                        variant="standard"
                        value={values.reason}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!isValid || remainingEntitlement - workingDays < 0 || workingDays === 0}
                      >
                        Submit
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                      <Typography sx={{ width: '100%' }} variant="body2">
                        This will use {workingDays} days allowance.
                      </Typography>
                      <Typography sx={{ width: '100%' }} variant="body2">
                        You will have {remainingEntitlement - workingDays} days remaining.
                      </Typography>
                    </Grid>
                  </LocalizationProvider>
                </Grid>
              </Form>
            )}
          </Formik>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Modal>
  );
}

CreateRequestFormModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  currentUserEmail: PropTypes.string,
  isApprover: PropTypes.bool,
};
