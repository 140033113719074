import Swal from 'sweetalert2';

export const sweetAlertPopupType = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  QUESTION: 'question',
};

export const sweetAlertInputType = {
  TEXT: 'text',
  EMAIL: 'email',
  URL: 'url',
  PASSWORD: 'password',
  TEXTAREA: 'textarea',
};

export function alertPopup(popupTitle, popupMessage, popupType) {
  Swal.fire({
    title: popupTitle,
    text: popupMessage,
    icon: popupType,
  });
}

export async function userConfirmationPopup(popupTitle, popupMessage) {
  let userConfirmationResult = null;

  await Swal.fire({
    title: popupTitle,
    text: popupMessage,
    icon: sweetAlertPopupType.QUESTION,
    showCancelButton: true,
    showConfirmButton: true,
  }).then((result) => {
    userConfirmationResult = result.isConfirmed;
  });

  return userConfirmationResult;
}

export async function userInputPopup(popupTitle, popupMessage, inputType) {
  let userInputResult;

  await Swal.fire({
    title: popupTitle,
    text: popupMessage,
    input: inputType,
    inputValue: '',
    showCancelButton: true,
  }).then((result) => {
    userInputResult = result.isDismissed ? false : result.value;
  });

  return userInputResult;
}
