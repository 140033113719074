import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminRoute from './components/routes/AdminRoute';
import AppliedRoute from './components/routes/AppliedRoute';
import ApproverRoute from './components/routes/ApproverRoute';
import ProtectedRoute from './components/routes/ProtectedRoute';
import Admin from './pages/admin/Admin';
import Calendar from './pages/calendar/Calendar';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import MyRequests from './pages/my-requests/MyRequests';
import NotFound from './pages/not-found/NotFound';
import Pending from './pages/pending-requests/Pending';
import ResetPassword from './pages/reset-password/ResetPassword';

export default function Routes({ appProps }) {
  return (
    <Switch>
      <ProtectedRoute path="/" exact component={Home} appProps={appProps} />
      <AppliedRoute path="/login" exact component={Login} appProps={appProps} />
      <AppliedRoute path="/resetPassword" exact component={ResetPassword} appProps={appProps} />
      <ApproverRoute path="/pending" exact component={Pending} appProps={appProps} />
      <ProtectedRoute path="/myrequests" exact component={MyRequests} appProps={appProps} />
      <ProtectedRoute path="/calendar" exact component={Calendar} appProps={appProps} />
      <AdminRoute path="/admin" exact component={Admin} appProps={appProps} />
      <Route component={NotFound} />
    </Switch>
  );
}

Routes.propTypes = {
  appProps: PropTypes.object,
};
