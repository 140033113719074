import { API } from 'aws-amplify';

export function getEntitlement(userEmail, period) {
  return API.get('vacationStationAPI', `/users/${userEmail}/entitlement/${period.substr(0, 4)}`);
}

export async function createEntitlement(request) {
  return API.post('vacationStationAPI', '/createEntitlement', {
    body: request,
  });
}

export function updateEntitlement(request) {
  return API.post('vacationStationAPI', `/users/${request.emailAddress}/entitlement/${request.holidayPeriod}`, {
    body: request,
  });
}
