import { API } from 'aws-amplify';

export async function getUsersByDepartment(departmentId) {
  return API.get('vacationStationAPI', encodeURI(`/departments/${departmentId}/users`));
}

export async function getUsersByTeam(teamId) {
  return API.get('vacationStationAPI', encodeURI(`/teams/${teamId}/users`));
}

export function getUser(email) {
  return API.get('vacationStationAPI', encodeURI('/users/' + email));
}

export async function getApprovers() {
  const users = await API.get('vacationStationAPI', encodeURI('/users?verbose=false'));

  return users.filter((user) => !!user.isApprover);
}

export async function getAllUsers(verbose) {
  return API.get('vacationStationAPI', encodeURI(`/users?verbose=${verbose}`));
}

export async function createUser(request) {
  return API.post('vacationStationAPI', '/users', {
    body: request,
  });
}

export async function updateUser(request, emailAddress) {
  return API.patch('vacationStationAPI', `/users/${emailAddress}`, {
    body: request,
  });
}
