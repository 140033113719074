import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class Dropdown extends Component {
  constructor(props) {
    super(props);
  }

  renderDataAsSelectItems(items) {
    let result = [];
    if (this.props.emptySelect) {
      result.push(
        <MenuItem key={-1} value="-1">
          {this.props.emptySelect}
        </MenuItem>,
      );
    }
    if (items && items.length > 0) {
      items.map((item, index) => {
        result.push(
          <MenuItem
            key={index}
            value={item[this.props.selectedValueField] ? item[this.props.selectedValueField] : item}
          >
            {item[this.props.selectedTextField] ? item[this.props.selectedTextField] : item}
          </MenuItem>,
        );
      });
    }
    return result;
  }

  render() {
    return (
      <FormControl fullWidth required={this.props.requiredField} variant="standard">
        <InputLabel>{this.props.label}</InputLabel>
        <Select
          label={this.props.label}
          id={this.props.id}
          name={this.props.name}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          value={this.props.value}
          fullWidth
          error={this.props.error}
        >
          {this.renderDataAsSelectItems(this.props.data)}
        </Select>
      </FormControl>
    );
  }
}

Dropdown.defaultProps = {
  requiredField: false,
};

Dropdown.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  emptySelect: PropTypes.string,
  selectedValueField: PropTypes.string,
  selectedTextField: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  requiredField: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.bool,
};

export default Dropdown;
