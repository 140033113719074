import { API } from 'aws-amplify';

export function getTeamsByDepartment(departmentId) {
  return API.get('vacationStationAPI', encodeURI(`/departments/${departmentId}/teams`));
}

export function getAllTeams() {
  return API.get('vacationStationAPI', `/teams?verbose=true`);
}

export async function createTeam(request) {
  return API.post('vacationStationAPI', `/departments/${request.departmentId}/teams`, {
    body: request,
  });
}

export async function updateTeam(request) {
  return API.patch('vacationStationAPI', `/departments/${request.departmentId}/teams/${request.teamId}`, {
    body: request,
  });
}

export async function deleteTeam(departmentId, teamId) {
  return API.del('vacationStationAPI', `/departments/${departmentId}/teams/${teamId}`);
}
