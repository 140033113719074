import { Link } from '@mui/material';
import moment from 'moment';
import Timeline from 'new-react-calendar-timeline/lib';
import 'new-react-calendar-timeline/lib/lib/Timeline.css';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { getEntitlement } from '../apis/entitlement-api';
import { exportUserRequests } from '../apis/export-api';
import { getRequestsByDepartment, getRequestsByDepartmentAndTeam } from '../apis/request-api';
import { getUser, getUsersByDepartment, getUsersByTeam } from '../apis/user-api';
import './CalendarTimeline.css';

export class CalendarTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.visibleTimeStart !== prevProps.visibleTimeStart ||
      this.props.visibleTimeEnd !== prevProps.visibleTimeEnd ||
      this.props.selectedDepartment !== prevProps.selectedDepartment ||
      this.props.selectedTeam !== prevProps.selectedTeam
    ) {
      await this.getData();
    }
  }

  async handleDownloadClick(event, userEmail) {
    event.preventDefault();
    event.stopPropagation();

    console.log(event);
    const userEntitlement = await getEntitlement(userEmail, this.props.selectedPeriod);
    const response = await exportUserRequests({
      emailAddress: userEmail,
      userTotalEntitlement: userEntitlement.total,
      userRemainingEntitlement: userEntitlement.remaining,
      period: this.props.selectedPeriod,
    });

    const link = document.createElement('a');
    link.href = response.s3DownloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async getData() {
    this.setState(() => ({ isLoading: true }));

    try {
      const currentUser = await getUser(this.props.currentUserEmail);
      const sortedUsers = await this.getSortedUsers();

      const groups = sortedUsers.map((user) => ({
        id: user.emailAddress,
        title:
          currentUser.isApprover || currentUser.isAdmin ? (
            <Link href="#" onClick={(e) => this.handleDownloadClick(e, user.emailAddress)}>
              {user.name}
            </Link>
          ) : (
            user.name
          ),
      }));
      const items = await this.getItems();

      this.setState(() => ({
        data: {
          groups,
          items,
        },
      }));
    } catch (e) {
      alert('DataError:' + e.code + ' ' + e.message);
    } finally {
      this.setState(() => ({ isLoading: false }));
    }
  }

  async getSortedUsers() {
    let users = [];
    if (!this.props.selectedTeam || this.props.selectedTeam === '-1') {
      users = await getUsersByDepartment(this.props.selectedDepartment);
    } else {
      users = await getUsersByTeam(this.props.selectedTeam);
    }

    return users.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
  }

  async getItems() {
    let requests = [];

    if (this.props.selectedDepartment && this.props.selectedTeam === '-1') {
      requests = await getRequestsByDepartment(this.props.selectedDepartment, this.props.selectedPeriod.substr(0, 4));
    } else {
      requests = await getRequestsByDepartmentAndTeam(
        this.props.selectedDepartment,
        this.props.selectedTeam,
        this.props.selectedPeriod.substr(0, 4),
      );
    }

    requests = this.formatRequestsIntoCalendarItems(requests);

    return requests;
  }

  formatRequestsIntoCalendarItems(requests) {
    return requests.map((request) => {
      const initials = request.userName.match(/[A-Z]/g).join('');

      let endDate = new Date(request.endDate);
      let startDate = new Date(request.startDate);

      switch (request.fullAMPM) {
        case 'full':
          startDate.setUTCHours(0, 0, 0);
          endDate.setUTCHours(23, 59, 59);
          break;
        case 'am':
          startDate.setUTCHours(0, 0, 0, 0);
          endDate.setUTCHours(12, 0, 0);
          break;
        case 'pm':
          startDate.setUTCHours(12, 0, 0);
          endDate.setUTCHours(23, 59, 59);
          break;
        default:
          break;
      }

      return {
        id: request.requestId,
        group: request.emailAddress,
        title: initials + ' Holiday',
        start_time: moment(startDate).valueOf(),
        end_time: moment(endDate).valueOf(),
        className: request.status,
        canMove: false,
        canResize: false,
      };
    });
  }

  render() {
    return (
      <div>
        {this.state.isLoading && (
          <div className="CalendarLoading">
            <CircularProgress />
          </div>
        )}
        {this.state && this.state.data && this.state.data.groups.length <= 0 && !this.state.isLoading && (
          <div>
            <Typography variant="h6" paddingTop="3%">
              There are no users for this Department/Team combination.
            </Typography>
          </div>
        )}
        {this.state && this.state.data && !this.state.isLoading && (
          <div className="calendar-timeline-background">
            <Timeline
              groups={this.state.data.groups}
              items={this.state.data.items}
              timeSteps={{
                second: 1,
                minute: 1,
                hour: 1,
                day: 1,
                month: 1,
                year: 1,
              }}
              itemRenderer={({ item }) => {
                return (
                  <div className="custom-item">
                    <span className="title">{item.title}</span>
                  </div>
                );
              }}
              {...this.props}
            />
          </div>
        )}
      </div>
    );
  }
}

CalendarTimeline.propTypes = {
  visibleTimeStart: PropTypes.object,
  visibleTimeEnd: PropTypes.object,
  selectedPeriod: PropTypes.string,
  currentUserEmail: PropTypes.string,
  selectedDepartment: PropTypes.string,
  selectedTeam: PropTypes.string,
};
