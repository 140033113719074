import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getEntitlement } from '../../apis/entitlement-api';
import { calculateCurrentPeriod } from '../../lib/helpers';
import { Paper, Typography, Divider } from '@mui/material';

Summary.propTypes = {
  email: PropTypes.string,
  period: PropTypes.string,
  setRemainingEntitlement: PropTypes.func,
};

export default function Summary({ email, period, setRemainingEntitlement }) {
  const [entitlement, setEntitlement] = useState({});

  useEffect(() => {
    async function fetchEntitlement() {
      try {
        if (!period) {
          period = calculateCurrentPeriod();
        }
        const entitlement = await getEntitlement(email, period);
        setEntitlement(entitlement);
        if (setRemainingEntitlement) {
          setRemainingEntitlement(entitlement.remainingEntitlement);
        }
      } catch (error) {
        setEntitlement({});
      }
    }

    fetchEntitlement();

    return () => setEntitlement({});
  }, [email, period]);

  return (
    <div>
      <Paper sx={{ padding: '10px', marginBottom: '20%', '& .MuiTypography-root': { margin: '10px' } }}>
        <Typography sx={{ margin: ' 0 0 15px 0 !important' }} variant="h6" textAlign="center">
          Summary
        </Typography>
        <Typography variant="body2">Period: {period}</Typography>
        <Divider />
        <Typography variant="body2">Entitlement: {entitlement.total} days</Typography>
        <Divider />
        <Typography variant="body2">Remaining Entitlement: {entitlement.remaining} days</Typography>
        <Divider />
      </Paper>
    </div>
  );
}
