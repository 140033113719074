import { API } from 'aws-amplify';

export async function getAllDepartments() {
  return API.get('vacationStationAPI', '/departments');
}

export async function getDepartment(departmentId) {
  return API.get('vacationStationAPI', encodeURI(`/departments/${departmentId}`));
}

export async function createDepartment(request) {
  return API.post('vacationStationAPI', '/departments', {
    body: request,
  });
}

export async function updateDepartment(request) {
  return API.patch('vacationStationAPI', `/departments/${request.departmentId}`, {
    body: request,
  });
}

export async function deleteDepartment(departmentId) {
  return API.del('vacationStationAPI', `/departments/${departmentId}`);
}
