import moment from 'moment';

export function calculateCurrentPeriod(basedOnDate) {
  const date = basedOnDate ? new Date(basedOnDate) : new Date();

  const month = date.getMonth();
  const year = 1900 + date.getYear();

  // is the currentMonth before May
  // e.g. 2021-01-01 = 2020-2021, and 2021-06-01 = 2021-2022
  let periodFromTo = `${year.toString()}-${(year + 1).toString()}`;
  if (month < 4) {
    periodFromTo = `${(year - 1).toString()}-${year.toString()}`;
  }

  return periodFromTo;
}

export function getPeriodOption(offset) {
  //take todays date and work out the current holiday period
  const today = new Date();

  let periods = [];
  let basedOnDate = new Date();
  for (let yearOffset = -offset; yearOffset <= offset; yearOffset++) {
    basedOnDate.setFullYear(today.getFullYear() + yearOffset);
    const period = calculateCurrentPeriod(basedOnDate);
    periods.push(period);
  }

  return periods;
}

export function getStartEndDatesForPeriod(period) {
  const startYear = period.substring(0, 4);
  const endYear = period.substring(5, 9);

  const firstOfAprilStartYear = moment(new Date(startYear, 4, 1, 0, 0, 0, 0)).format('DD/MM/YYYY');
  const endOfMarchEndYear = moment(new Date(endYear, 4, 1, 0, 0, 0, 0)).subtract(1, 'days').format('DD/MM/YYYY');

  return {
    startDate: firstOfAprilStartYear,
    endDate: endOfMarchEndYear,
  };
}

export function removeSpecialCharacters(str) {
  return str.replace("'", '');
}

export async function filterData(data, period) {
  if (period == null) {
    period = calculateCurrentPeriod();
  }
  const periodDates = getStartEndDatesForPeriod(period);
  if (data != null && data.length > 0) {
    data = data.filter(
      (d) =>
        moment(d.startDate, 'YYYY-MM-DD') >= moment(periodDates.startDate, 'DD/MM/YYYY') &&
        moment(d.startDate, 'YYYY-MM-DD') <= moment(periodDates.endDate, 'DD/MM/YYYY'),
    );
  }

  return data;
}
