import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function ProtectedRoute({ component: C, appProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        appProps.isAuthenticated === false ? <Redirect to="/login" /> : <C {...props} {...appProps} />
      }
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  appProps: PropTypes.object,
};
