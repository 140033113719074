import { Container, CssBaseline } from '@mui/material';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getUser } from './apis/user-api';
import Footer from './components/layout/Footer';
import NavBar from './components/layout/NavBar';
import * as sweetAlertService from './lib/sweet-alert-service';
import Routes from './Routes';

function App(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [currentUserEmailAddress, setCurrentUserEmailAddress] = useState(null);

  App.propTypes = {
    history: PropTypes.object,
  };

  useEffect(() => {
    //was getting a lint error regarding this function and moving it inside useEffect or alternatively using
    // //eslint-disable-next-line react-hooks/exhaustive-deps before the call to the function prevents this error - dont understand the latter
    async function onLoad() {
      try {
        await Auth.currentSession();
        const authenticatedUserLocal = await Auth.currentUserInfo();
        await setAuthenticatedUserDetails(authenticatedUserLocal);
      } catch (e) {
        if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
          //do nothing if we dont have a current user/session
        } else if (e !== 'No current user' && e.code !== 'NotAuthorizedException') {
          sweetAlertService.alertPopup(
            'User Error',
            'You are not authorised to view the app. Please register an account, or contact the BSD team.',
            sweetAlertService.sweetAlertPopupType.ERROR,
          );
        }
      }

      setIsAuthenticating(false);
    }

    onLoad();
  }, []);

  async function setAuthenticatedUserDetails(authenticatedUser) {
    try {
      setAuthenticatedUser(authenticatedUser);
      if (authenticatedUser != null && authenticatedUser?.attributes?.email) {
        const user = await getUser(authenticatedUser.attributes.email);
        setUserDetails(user);
        setIsAuthenticated(true);
      } else {
        setUserDetails(null);
        setIsAuthenticated(false);
      }
    } catch (e) {
      sweetAlertService.alertPopup(
        'User Error',
        'Failed to setup a user account. Please try again, or contact the BSD team.',
        sweetAlertService.sweetAlertPopupType.ERROR,
      );
    }
  }

  async function handleLogout() {
    const logoutResult = await sweetAlertService.userConfirmationPopup('Log out', 'Are you sure you wish to log out?');

    if (logoutResult) {
      await Auth.signOut();

      props.history.push('/login');
      setAuthenticatedUserDetails(null);
    }
  }

  return (
    <div>
      <CssBaseline />
      {!isAuthenticating && (
        <div className="app-main">
          <div className="App">
            <NavBar
              isAdmin={userDetails && userDetails.isAdmin}
              isApprover={userDetails && userDetails.isApprover}
              isAuthenticated={isAuthenticated}
              handleLogout={handleLogout}
              userName={userDetails ? userDetails.name : ''}
            ></NavBar>
            <Container maxWidth="lg">
              <Routes
                appProps={{
                  isAuthenticated,
                  setIsAuthenticated,
                  authenticatedUser,
                  setAuthenticatedUserDetails,
                  userDetails,
                  setUserDetails,
                  currentUserEmailAddress,
                  setCurrentUserEmailAddress,
                }}
              />
            </Container>

            <Footer
              isAuthenticated={isAuthenticated}
              currentUserEmail={userDetails ? userDetails.emailAddress : ''}
              isApprover={userDetails ? userDetails.isApprover : false}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(App);
