import LockIcon from '@mui/icons-material/Lock';
import { Avatar, Box, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React from 'react';
import * as sweetAlertService from '../../lib/sweet-alert-service';

export default function Login(props) {
  async function handleUserNotconfirmed(data) {
    try {
      await Auth.resendSignUp(data.get('email'));
      props.setAuthenticatedUserDetails({
        userEmail: data.get('email'),
        password: data.get('password'),
      });

      props.history.push('/register');
    } catch (e) {
      sweetAlertService.alertPopup(
        'Registration Error',
        'Failed to resend auth code. Please try again or contact the BSD team',
        sweetAlertService.sweetAlertPopupType.ERROR,
      );
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.set('email', data.get('email').toLowerCase());
    try {
      const newUser = await Auth.signIn(data.get('email'), data.get('password'));

      if (newUser && newUser.attributes['custom:reset_pass'] && newUser.attributes['custom:reset_pass'] === 'true') {
        props.setIsAuthenticated(false);
        await Auth.signOut();
        props.history.push('/resetPassword');
      } else {
        props.setCurrentUserEmailAddress(data.get('email').toLowerCase());
        props.setAuthenticatedUserDetails(newUser);
        props.setIsAuthenticated(true);
        props.history.push('/');
      }
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        sweetAlertService.alertPopup(
          'Email Confirmation',
          'This email registration has not been confirmed, resending the confirmation code to complete the sign up',
          sweetAlertService.sweetAlertPopupType.INFO,
        );
        await handleUserNotconfirmed(data);
      } else if (e.code === 'NotAuthorizedException') {
        sweetAlertService.alertPopup(
          'Login Failed',
          'Sorry there was an issue logging you in, please check your password and try again',
          sweetAlertService.sweetAlertPopupType.ERROR,
        );
      } else {
        sweetAlertService.alertPopup(
          'Login Failed',
          'Sorry there was a problem logging you in.  Please check your email and password',
          sweetAlertService.sweetAlertPopupType.ERROR,
        );
      }
    }
  }

  return (
    <Box
      sx={{
        margin: '6% 0 6% 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, backgroundColor: '#003049' }}>
        <LockIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          variant="outlined"
          inputProps={{ style: { textTransform: 'lowercase' } }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          variant="outlined"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            ':hover': {
              backgroundColor: 'hsl(201, 100%, 24%)',
            },
            backgroundColor: '#003049',
          }}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/resetPassword" variant="body2">
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

Login.propTypes = {
  setAuthenticatedUserDetails: PropTypes.func,
  setIsAuthenticated: PropTypes.func,
  setCurrentUserEmailAddress: PropTypes.func,
  history: PropTypes.object,
};
