import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { AppBar, Fab, Toolbar, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CreateRequestFormModal from '../../pages/create-request/CreateRequestFormModal';

export default function Footer(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <AppBar
      sx={{
        backgroundColor: '#C8102E',
        top: 'auto',
        bottom: 0,
        textAlign: 'center',
        '& div': {
          margin: '0 auto',
        },
      }}
    >
      <Toolbar variant="dense">
        {props && props.isAuthenticated && props.currentUserEmail ? (
          <div>
            <Fab
              sx={{
                position: 'absolute',
                zIndex: 1,
                top: -45,
                left: 0,
                right: 0,
                margin: '0 auto',
                color: '#FFFFFF',
                ':hover': {
                  backgroundColor: 'hsl(201, 100%, 24%)',
                },
                backgroundColor: '#003049',
              }}
              aria-label="add"
              onClick={handleOpen}
            >
              <CalendarTodayIcon />
              <AddIcon fontSize="small" />
            </Fab>
            <CreateRequestFormModal
              open={open}
              handleClose={handleClose}
              currentUserEmail={props.currentUserEmail}
              isApprover={props.isApprover}
            />
          </div>
        ) : (
          ''
        )}
        <div>
          <Typography variant="body2" align="center">
            Copyright {new Date().getFullYear().toString()} &copy; CEF
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Footer.propTypes = {
  isAuthenticated: PropTypes.bool,
  currentUserEmail: PropTypes.string,
  isApprover: PropTypes.bool,
};
