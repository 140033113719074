import { API } from 'aws-amplify';

export function createRequest(request) {
  return API.post('vacationStationAPI', '/requests', {
    body: request,
  });
}

export async function getMyRequests(currentUserEmail, period) {
  return API.get('vacationStationAPI', encodeURI(`/users/${currentUserEmail}/requests/period/${period.substr(0, 4)}`));
}

export function getPendingRequests(currentUserEmail) {
  return API.get('vacationStationAPI', encodeURI(`/approvers/${currentUserEmail}/requests`));
}

export function getRequestsForCalendar(userEmail, period) {
  return API.get('vacationStationAPI', encodeURI(`/users/${userEmail}/requests/period/${period}`));
}

export function getRequestsByDepartment(departmentId, holidayPeriod) {
  return API.get('vacationStationAPI', `/departments/${departmentId}/requests/period/${holidayPeriod}`);
}

export function getRequestsByDepartmentAndTeam(departmentId, teamId, holidayPeriod) {
  return API.get('vacationStationAPI', `/departments/${departmentId}/teams/${teamId}/requests/period/${holidayPeriod}`);
}

export function cancelRequest(emailAddress, startDate, requestId) {
  return API.del('vacationStationAPI', `/users/${emailAddress}/requests/${startDate}/id/${requestId}`);
}

export function updateRequestStatus(
  userEmail,
  startDate,
  requestId,
  status,
  approverComment = null,
  endDate,
  requestorName,
  fullAMPM = null,
) {
  return API.put('vacationStationAPI', `/users/${userEmail}/requests/${startDate}`, {
    body: {
      status: status.toLowerCase(),
      requestId,
      approverComment: approverComment ? approverComment : '',
      endDate,
      requestorName,
      fullAMPM,
    },
  });
}

export function calculateRequestCost(startDate, endDate, fullAMPM) {
  let request = {
    queryStringParameters: {
      startDate: startDate,
      endDate: endDate,
      fullAMPM: fullAMPM ? fullAMPM.toString() : null,
    },
  };

  return API.get('vacationStationAPI', '/requests/cost', request);
}
