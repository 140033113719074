import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Fab,
  Grid,
  Modal,
  Typography,
  Divider,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { getTeamsByDepartment } from '../../../apis/teams-api';
import { getAllDepartments } from '../../../apis/department-api';
import { createUser } from '../../../apis/user-api';
import * as sweetAlertService from '../../../lib/sweet-alert-service';

const validationSchema = yup.object({
  name: yup.string().required('Please enter a Name'),
  departmentId: yup.string().required().min(4, 'Please select a Department.'),
  teamId: yup.string().required().min(4, 'Please select a Team.'),
  emailAddress: yup.string().email().required('Please enter a valid email address'),
  currentEntitlement: yup.number().required().min(0, 'Entitlement cannot be less than 0'),
  recurringEntitlement: yup.number().required().min(0, 'Entitlement cannot be less than 0'),
});

export default function CreateUserFormModal({ open, handleCreateUserClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState('-1');
  const [availableDepartments, setAvailableDepartments] = useState(null);
  const [availableTeams, setAvailableTeams] = useState([]);

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      setAvailableDepartments(await getAllDepartments());
      setIsLoading(false);
    }
    onLoad();
  }, []);

  useEffect(() => {
    async function onDepartmentChange() {
      if (!selectedDepartment || selectedDepartment === -1) {
        setAvailableTeams(null);
      } else {
        const teams = await getTeamsByDepartment(selectedDepartment);
        setAvailableTeams(teams);
      }
    }
    onDepartmentChange();
  }, [selectedDepartment]);

  const initialFormValues = {
    name: '',
    emailAddress: '',
    departmentId: '-1',
    teamId: '-1',
    isApprover: false,
    isAdmin: false,
    isDisabled: false,
    currentEntitlement: 0,
    recurringEntitlement: 0,
  };

  const onSubmit = async (values) => {
    const body = {
      departmentId: values.departmentId,
      teamId: values.teamId,
      name: values.name,
      emailAddress: values.emailAddress.toLowerCase(),
      currentEntitlement: values.currentEntitlement,
      recurringEntitlement: values.recurringEntitlement,
      isApprover: values.isApprover,
      isAdmin: values.isAdmin,
      isDisabled: values.isDisabled,
    };

    try {
      await createUser(body);
      sweetAlertService.alertPopup('Success', '', sweetAlertService.sweetAlertPopupType.SUCCESS);
    } catch (error) {
      sweetAlertService.alertPopup('Failure', 'Failed to Create User', sweetAlertService.sweetAlertPopupType.ERROR);
    }

    handleCreateUserClose();
  };

  return (
    <Modal open={open} onClose={handleCreateUserClose} sx={{ zIndex: 1000 }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        {!isLoading && availableDepartments ? (
          <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, touched, errors, handleChange, isValid, setFieldValue }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    '& .MuiFormControl-root': {
                      width: '100% !important',
                    },
                  }}
                >
                  <Grid item xs={11} sm={11} md={11}>
                    <Typography component="h1" variant="h5">
                      {'Create New User'}
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid item xs={1} sm={1} md={1}>
                    <Fab
                      color="primary"
                      aria-label="add"
                      onClick={handleCreateUserClose}
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: '2.5px',
                        right: '2.5px',
                      }}
                    >
                      <CloseIcon />
                    </Fab>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="name"
                      name="name"
                      label="Name"
                      variant="standard"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="departmentId"
                      name="departmentId"
                      label="Department"
                      select
                      variant="standard"
                      value={values.departmentId}
                      onChange={(event) => {
                        values.departmentId = event.target.value;
                        setSelectedDepartment(event.target.value);
                      }}
                      fullWidth
                      error={touched.departmentId && !!errors.departmentId}
                      helperText={touched.departmentId && errors.departmentId}
                    >
                      <MenuItem value="-1">
                        <em>Please select a Department</em>
                      </MenuItem>
                      {availableDepartments.map((department) => (
                        <MenuItem key={department.departmentId} value={department.departmentId}>
                          {department.departmentName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="teamId"
                      name="teamId"
                      label="Team"
                      select
                      variant="standard"
                      value={values.teamId}
                      onChange={handleChange}
                      fullWidth
                      error={touched.teamId && !!errors.teamId}
                      helperText={touched.teamId && errors.teamId}
                    >
                      <MenuItem value="-1">
                        <em>Please select a Team</em>
                      </MenuItem>
                      {availableTeams.map((team) => (
                        <MenuItem key={team.teamId} value={team.teamId}>
                          {team.teamName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="emailAddress"
                      name="emailAddress"
                      label="Email"
                      variant="standard"
                      value={values.emailAddress}
                      onChange={handleChange}
                      disabled={false}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Typography>Entitlement</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          id="currentEntitlement"
                          name="currentEntitlement"
                          label="Current Entitlement"
                          variant="standard"
                          type="number"
                          value={values.currentEntitlement}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="recurringEntitlement"
                          name="recurringEntitlement"
                          label="Recurring Entitlement"
                          variant="standard"
                          type="number"
                          value={values.recurringEntitlement}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          label="Administrator"
                          labelPlacement="end"
                          control={
                            <Checkbox
                              name="isAdmin"
                              checked={values.isAdmin}
                              onChange={(event) => {
                                setFieldValue('isAdmin', event.target.checked);
                              }}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          label="Approver"
                          labelPlacement="end"
                          control={
                            <Checkbox
                              name="isApprover"
                              checked={values.isApprover}
                              onChange={(event) => {
                                setFieldValue('isApprover', event.target.checked);
                              }}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
                      Create User
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          label="Disabled"
                          labelPlacement="end"
                          control={
                            <Checkbox
                              name="isDisabled"
                              checked={values.isDisabled}
                              onChange={(event) => {
                                setFieldValue('isDisabled', event.target.checked);
                              }}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Modal>
  );
}

CreateUserFormModal.propTypes = {
  open: PropTypes.bool,
  handleCreateUserClose: PropTypes.func,
  user: PropTypes.object,
};
