import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Fab, Grid, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import * as sweetAlertService from '../../../lib/sweet-alert-service';
import { createDepartment, updateDepartment, deleteDepartment } from '../../../apis/department-api';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const validationSchema = yup.object({
  department: yup.string(),
});

export default function CreateDepartmentFormModal({ open, handleCreateDepartmentClose, department }) {
  const onSubmit = async (values) => {
    const body = {
      departmentName: values.departmentName,
    };

    if (department) {
      body.departmentId = department.departmentId;
    }

    try {
      department ? await updateDepartment(body) : await createDepartment(body);
      sweetAlertService.alertPopup(
        'Success',
        `The ${values.departmentName} department has been successfully created/updated`,
        sweetAlertService.sweetAlertPopupType.SUCCESS,
      );
    } catch (error) {
      sweetAlertService.alertPopup(
        'Failure',
        `The ${values.departmentName} department failed to create/update`,
        sweetAlertService.sweetAlertPopupType.ERROR,
      );
    }
    handleCreateDepartmentClose();
  };

  const onDelete = async () => {
    const userConfirmed = await sweetAlertService.userConfirmationPopup(
      'Delete Department',
      `Are you sure you wish to delete the ${department.departmentName} department?`,
    );

    if (userConfirmed) {
      try {
        await deleteDepartment(department.departmentId);
        sweetAlertService.alertPopup(
          'Success',
          `The ${department.departmentName} department has been successfully deleted`,
          sweetAlertService.sweetAlertPopupType.SUCCESS,
        );
      } catch (error) {
        sweetAlertService.alertPopup(
          'Failure',
          `The ${department.departmentName} department has failed to delete. You can only delete departments that do not contain teams.`,
          sweetAlertService.sweetAlertPopupType.ERROR,
        );
      }
      handleCreateDepartmentClose();
    }
  };

  return (
    <Modal open={open} onClose={handleCreateDepartmentClose} sx={{ zIndex: 1000 }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Formik
          initialValues={{
            departmentName: department ? department.departmentName : '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, touched, errors, handleChange }) => (
            <Form>
              <Grid
                container
                spacing={2}
                sx={{
                  '& .MuiFormControl-root': {
                    width: '100% !important',
                  },
                }}
              >
                <Grid item xs={11} sm={11} md={11}>
                  <Typography component="h1" variant="h5">
                    {department ? 'Edit' : 'Create'} Department
                  </Typography>
                </Grid>

                <Grid item xs={1} sm={1} md={1}>
                  <Fab
                    color="primary"
                    aria-label="add"
                    onClick={handleCreateDepartmentClose}
                    size="small"
                    sx={{
                      position: 'absolute',
                      top: '2.5px',
                      right: '2.5px',
                    }}
                  >
                    <CloseIcon />
                  </Fab>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="departmentName"
                    name="departmentName"
                    label="Department Name"
                    variant="standard"
                    value={values.departmentName}
                    onChange={handleChange}
                    error={touched.approver && !!errors.approver}
                    helperText={touched.approver && errors.approver}
                  />
                </Grid>

                {department ? (
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={values.departmentName.length <= 0}
                        endIcon={<CheckIcon />}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Button
                        variant="contained"
                        color="error"
                        disabled={values.departmentName.length <= 0}
                        endIcon={<ClearIcon />}
                        onClick={onDelete}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={values.departmentName.length <= 0}
                      endIcon={<CheckIcon />}
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

CreateDepartmentFormModal.propTypes = {
  open: PropTypes.bool,
  handleCreateDepartmentClose: PropTypes.func,
  department: PropTypes.object,
};
