import { Box, Button, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as yup from 'yup';
import * as sweetAlertService from '../../lib/sweet-alert-service';

const validationSchema = yup.object({
  email: yup.string().required('Please enter an email address.').email('Please enter an email address.').min(1),
});

const passwordResetValidationSchema = yup.object({
  password: yup
    .string()
    .required('Please enter a new password.')
    .min(10, 'Passwords must be at least 10 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
      'Password must be a minimum of 10 characters and contain at least one uppercase, lowercase, number and special character.',
    ),
  confirmationCodeNum: yup
    .string()
    .required('Please enter your confirmation code')
    .min(6, 'Confirmation codes are 6 digits')
    .matches(/^[0-9]+$/, 'Confirmation codes are digits only'),
});

export default function ResetPasswordRequest(props) {
  const [userEmail, setUserEmail] = useState('');

  const handleResetSubmit = async (values) => {
    try {
      //store the user email
      setUserEmail(values.email);
      //try to create an account
      await Auth.forgotPassword(values.email);
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        sweetAlertService.alertPopup(
          'Password Reset Error',
          'This email registration has not been confirmed, resending the confirmation code to complete the sign up.',
          sweetAlertService.sweetAlertPopupType.WARNING,
        );
        await handleUserNotconfirmed(values);
      } else {
        sweetAlertService.alertPopup(
          'Password Reset Error',
          'An error has occured when attempting to reset password. Please try again, or contact the BSD team.',
          sweetAlertService.sweetAlertPopupType.ERROR,
        );
      }
    }
  };

  async function handleUserNotconfirmed(fields) {
    try {
      const newUser = await Auth.resendSignUp(fields.email);

      props.setAuthenticatedUser(newUser);
    } catch (e) {
      sweetAlertService.alertPopup(
        'Password Reset Error',
        'An error has occured when attempting to reset password. Please try again, or contact the BSD team.',
        sweetAlertService.sweetAlertPopupType.ERROR,
      );
    }
  }

  const handleResetConfirmSubmit = async (values) => {
    try {
      await Auth.forgotPasswordSubmit(values.email, values.confirmationCodeNum, values.password);
      const newUser = await Auth.signIn(values.email, values.password);
      const authedUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(authedUser, {
        'custom:reset_pass': 'false',
      });

      sweetAlertService.alertPopup(
        'Password Reset Success',
        'Your password has been successfully reset',
        sweetAlertService.sweetAlertPopupType.SUCCESS,
      );

      props.setAuthenticatedUserDetails(newUser);
      props.setIsAuthenticated(true);
      props.history.push('/');
    } catch (e) {
      if (e.code === 'InvalidPasswordException') {
        sweetAlertService.alertPopup(
          'Password Validation Error',
          'This password does not conform to the password policy: ' + e.message,
          sweetAlertService.sweetAlertPopupType.ERROR,
        );
      }
      if (e.code === 'CodeMismatchException') {
        sweetAlertService.alertPopup(
          'Password Reset Error',
          'Invalid Verification code provided, please try again',
          sweetAlertService.sweetAlertPopupType.ERROR,
        );
      } else {
        sweetAlertService.alertPopup(
          'Password Reset Error',
          'An error has occured when attempting to reset password. Please try again, or contact the BSD team.',
          sweetAlertService.sweetAlertPopupType.ERROR,
        );
      }
    }
  };

  function renderForm() {
    return (
      <Box
        sx={{
          margin: '6% 0 6% 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box sx={{ mt: 1, width: '45%' }}>
          <Formik
            //Strange behaviour encountered when two forms on same page - giving them a key prevent this!
            key="Reset"
            initialValues={{
              email: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleResetSubmit}
          >
            {({ values, errors, touched, handleChange, isValid }) => (
              <Form>
                <TextField
                  id="email"
                  name="email"
                  label="Email Address"
                  variant="outlined"
                  margin="normal"
                  autoFocus
                  fullWidth
                  autoComplete="off"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    ':hover': {
                      backgroundColor: 'hsl(201, 100%, 24%)',
                    },
                    backgroundColor: '#003049',
                  }}
                  disabled={!isValid}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    );
  }

  function renderConfirmationForm() {
    return (
      <Box
        sx={{
          margin: '6% 0 6% 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">Reset Password</Typography>
        <Box sx={{ mt: 1, width: '45%' }}>
          <Formik
            //Strange behaviour encountered when two forms on same page - giving them a key prevent this!
            key="ResetConfirm"
            initialValues={{
              email: userEmail,
              confirmationCodeNum: '',
              password: '',
            }}
            validationSchema={passwordResetValidationSchema}
            onSubmit={handleResetConfirmSubmit}
          >
            {({ values, errors, touched, handleChange, isValid }) => (
              <Form autoComplete="off">
                <TextField
                  id="confirmationCodeNum"
                  name="confirmationCodeNum"
                  label="Confirmation Code"
                  variant="outlined"
                  fullWidth
                  autoComplete="one-time-code"
                  margin="normal"
                  value={values.confirmationCodeNum}
                  onChange={handleChange}
                  error={touched.confirmationCodeNum && !!errors.confirmationCodeNum}
                  helperText={touched.confirmationCodeNum && errors.confirmationCodeNum}
                />
                <Typography variant="caption">Please check your email for the code.</Typography>
                <TextField
                  id="password"
                  name="password"
                  label="New Password"
                  variant="outlined"
                  fullWidth
                  autoComplete="one-time-code"
                  margin="normal"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                />

                <Typography variant="caption">
                  Your password must contain at least 10 characters and include at least one of each of the following:
                  <ul>
                    <li>Lowercase letter</li>
                    <li>Uppercase letter</li>
                    <li>Number</li>
                    <li>Special character (! $ % & * @ ?)</li>
                  </ul>
                </Typography>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!isValid}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    );
  }

  return <div className="Reset">{userEmail === '' ? renderForm() : renderConfirmationForm()}</div>;
}

ResetPasswordRequest.propTypes = {
  history: PropTypes.node,
  setAuthenticatedUser: PropTypes.func,
  setAuthenticatedUserDetails: PropTypes.func,
  setIsAuthenticated: PropTypes.func,
};
