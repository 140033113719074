/* eslint-disable no-unused-vars */
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function ContentTable(props) {
  return (
    <TableContainer sx={{ marginBottom: '9%', textTransform: 'capitalize' }} component={Paper}>
      <Table size="medium" stickyHeader>
        <TableHead>
          <TableRow>
            {props.headers.map((header, index) => {
              return (
                <TableCell align="center" key={index}>
                  {header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => {
            return (
              <TableRow key={index}>
                {Object.keys(row).map((key, index) => {
                  return (
                    <TableCell align="center" key={index}>
                      {row[key]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ContentTable.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array,
};
