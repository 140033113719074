import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function AdminRoute({ component: C, appProps, ...rest }) {
  const redirectRoute = appProps.isAuthenticated === false ? '/login' : '/myrequests';
  return (
    <Route
      {...rest}
      render={(props) =>
        appProps.isAuthenticated === false || (appProps.userDetails && !appProps.userDetails.isAdmin) ? (
          <Redirect to={redirectRoute} />
        ) : (
          <C {...props} {...appProps} />
        )
      }
    />
  );
}

AdminRoute.propTypes = {
  component: PropTypes.func,
  appProps: PropTypes.object,
};
