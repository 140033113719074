import React from 'react';
import PropTypes from 'prop-types';

export default function Home(props) {
  return (
    <div className="Home">
      {props.isAuthenticated ? props.history.push('/myrequests') : props.history.push('/login')}
    </div>
  );
}

Home.propTypes = {
  isAuthenticated: PropTypes.bool,
  history: PropTypes.object,
};
